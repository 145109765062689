import React from 'react'
import './banner.css'
import {FaPhoneAlt} from "react-icons/fa"
import {MdEmail} from "react-icons/md"
const Banner = () => {
  return (
    <div className='banner'>
        <div className="phone-numbers">
            <FaPhoneAlt color='#98c03b'/>
            <p>+(234)816 266 1972</p>
        </div>
        <div className="email">
            <MdEmail color='#98c03b'/>
            <p>info@newistt.com</p>
        </div>
    </div>
  )
}

export default Banner